import React from 'react';
import hero from '../images/hero.png';

function Hero() {
  return (
    <>
    <section id="hero" className="d-flex align-items-center">
    <div className="">
      <div className="row gy-4">
        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1 className='head-title1'>
          <span style={{color:"#ef7f1b",fontWeight:"bold"}}>Dcode Systems</span>
          </h1>
          <h1 className='head-title'>
          Unlocking Your Digital Potential
          </h1>
          <h2>
          Our mission is to empower you to unlock your digital potential. Through innovative web development solutions, transformative internship opportunities, and expertise in digital marketing, we guide you towards excellence in the digital landscape. Join us on a journey of growth and success as we decode the possibilities for your digital future.
          </h2>
          <div>
            <a href="https://razorpay.me/@dcodesystems" className="btn-get-started scrollto">
              Pay Us
            </a>
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img">
          <img
            src={hero}
            className="img-fluid animated"
            alt="hero-img"
          />
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Hero
