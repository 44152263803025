import React from 'react';
import internship from '../images/internship.svg';
import marketing from '../images/marketing.svg';
import webdev from '../images/webdev.jpg';

function Services() {
  return (
    <section id="services" className="services section-bg">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Services</h2>
        <p>Check out the great services we offer</p>
      </div>

      <div className="row rowcenter">
        <div
          className="col-md-6 col-lg-5 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay={400}
        >
          <div className="icon-box">
            <div className="icon">
              {/* <i className="bx bx-world" /> */}
              <img src={webdev} alt="dataservices" className='img-icon'/>
            </div>
            <h4 className="title">
            Web Development
            </h4>
            <p className="description">
            Elevate your online presence with our expert web development services. We create seamless, user-friendly websites that captivate and engage your audience, ensuring your digital footprint leaves a lasting impression.
            </p>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-5 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay={100}
        >
          <div className="icon-box">
            <div className="icon">
              {/* <i className="bx bxs-window-alt" /> */}
              <img src={marketing} alt="erp" className='img-icon-marketing' />
            </div>
            <h4 className="title">
            Digital Marketing
            </h4>
            <p className="description">
            Supercharge your brand visibility with our comprehensive digital marketing solutions. From strategic planning to execution, we specialize in social media management, SEO, and content marketing to drive measurable results and enhance your online impact.
            </p>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-5 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay={100}
        >
          <div className="icon-box">
            <div className="icon">
              {/* <i className="bx bxs-window-alt" /> */}
              <img src={internship} alt="erp" className='img-icon' />
            </div>
            <h4 className="title">
            Internship and Projects
            </h4>
            <p className="description">
            Join our dynamic internship programs for hands-on experience and collaboration with industry experts. Our carefully curated projects provide valuable insights, equipping participants with the skills necessary for a successful career in the ever-evolving tech landscape.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Services
