import React from 'react';


function Whyus() {
  return (
    <>
    <section id="whyus" className="about container">
      <div className="container">
      <div className="section-title">
        <h2>Why Choose Us</h2>
        <p>
            Know more about our values
        </p>
      </div>
        <div className="row" style={{justifyContent: "center"}}>
          <div className="col-lg-5 pt-lg-4">
              <p data-aos="fade-up" data-aos-delay={100}>
              Choose Dcode Systems for a journey fueled by expertise, tailored solutions, and proven results. We're not just your service provider; we're your collaborative partner in achieving digital success. With a team dedicated to innovation, a commitment to personalized excellence, and a track record of delivering tangible outcomes, Dcode Systems is your pathway to a thriving digital presence. Join us, and let's decode success together in the ever-evolving tech landscape.</p>
          </div>
          <div className="col-lg-5 d-flex align-items-center justify-content-center about-img">
            <img
              src="assets/img/growth.svg"
              className="img-fluid"
              alt=""
              data-aos="zoom-in"
            />
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Whyus;
