import React from 'react';


function About() {
  return (
    <>
    <section id="about" className="about container">
      <div className="container">
      <div className="section-title">
        <h2>About Us</h2>
        <p>
            Explore our mission
        </p>
      </div>
        <div className="row" style={{justifyContent: "center"}}>
          <div className="col-lg-5 d-flex align-items-center justify-content-center about-img">
            <img
              src="assets/img/about-img.svg"
              className="img-fluid"
              alt=""
              data-aos="zoom-in"
            />
          </div>
          <div className="col-lg-6 pt-5 pt-lg-4">
              <p data-aos="fade-up" data-aos-delay={100}>
              At Dcode Systems, we are more than just a tech company – we're a community of passionate individuals dedicated to fostering innovation and growth. Our team of experts brings a wealth of experience in web development, creating a dynamic environment where learning and collaboration thrive. Our mission is to bridge the gap between potential and achievement, providing not only top-notch web development solutions but also valuable internship opportunities that shape the next generation of digital leaders. We envision a future where every individual can harness the power of technology, and our commitment is to pave the way for your success in the ever-evolving digital landscape. Join us, and together, let's decode the future.</p>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default About
